<template>
	<div>
		<CustomModal
			:title="$t('ChangePwd.Title')"
			:submit-text="$t('ChangePwd.Submit')"
			:cancel-text="$t('ChangePwd.Cancel')"
			size="lg"
			@submit-modal="changePwd"
			@cancel-modal="cancel"
		>
			<template #content>
				<div class="change-pwd-form custom-form">
					<ValidationObserver ref="form" v-slot="{ handleSubmit }">
						<form @submit.prevent="handleSubmit(changePwd)">
							<!-- New Password -->
							<div class="form-group">
								<ValidationProvider
									v-slot="{ classes, errors }"
									name="auth_new_password"
									:rules="`requiredExport|max:${maxLength}|password`"
									mode="lazy"
								>
									<div :class="[classes, 'row']">
										<label class="col-4 col-auto col-form-label" for="new-password">{{
											$t("ChangePwd.NewPassword")
										}}</label>
										<div class="col-8 col-md-6">
											<div class="position-relative">
												<input
													id="new-password"
													v-model="changePwdForm.password"
													class="form-control"
													:placeholder="$t('ChangePwd.NewPassword')"
													:type="showNewPwd ? 'password' : 'text'"
													:maxlength="maxLength"
													autocomplete="new-password"
												/>
												<div v-if="changePwdForm.password" @click="showNewPassword">
													<span v-if="!showNewPwd" class="input-group-text icon-input">
														<font-awesome-icon
															:title="$t('ChangePwd.hidePwd')"
															:icon="['fas', 'eye-slash']"
														/>
													</span>

													<span v-else class="input-group-text icon-input">
														<font-awesome-icon
															:title="$t('ChangePwd.showPwd')"
															:icon="['fas', 'eye']"
														/>
													</span>
												</div>
											</div>
											<div class="error-mess">
												{{ errors[0] }}
											</div>
										</div>
									</div>
								</ValidationProvider>
							</div>

							<!-- Confirm New Password -->
							<div class="form-group">
								<ValidationProvider
									v-slot="{ classes, errors }"
									name="auth_confirm_password"
									:rules="`requiredExport|max:${maxLength}|confirmedBy:@auth_new_password`"
									mode="lazy"
								>
									<div :class="[classes, 'row']">
										<label for="confirm-password" class="col-4 col-auto col-form-label">{{
											$t("ChangePwd.ConfirmPassword")
										}}</label>
										<div class="col-8 col-md-6">
											<div class="position-relative">
												<input
													id="confirm-password"
													v-model="changePwdForm.passwordConfirm"
													class="form-control"
													:placeholder="$t('ChangePwd.ConfirmPassword')"
													:type="showConfirmPwd ? 'password' : 'text'"
													:maxlength="maxLength"
													autocomplete="new-password"
												/>
												<div v-if="changePwdForm.passwordConfirm" @click="showConfirmPassword">
													<span v-if="!showConfirmPwd" class="input-group-text icon-input">
														<font-awesome-icon
															:title="$t('ChangePwd.hidePwd')"
															:icon="['fas', 'eye-slash']"
														/>
													</span>

													<span v-else class="input-group-text icon-input">
														<font-awesome-icon
															:title="$t('ChangePwd.showPwd')"
															:icon="['fas', 'eye']"
														/>
													</span>
												</div>
											</div>
											<div class="error-mess">
												{{ errors[0] }}
											</div>
										</div>
									</div>
								</ValidationProvider>
							</div>
						</form>
					</ValidationObserver>
				</div>
			</template>
		</CustomModal>
	</div>
</template>

<script>
import { AUTH_ROLE } from "@/shared/plugins/constants"
import { CHANGE_PASSWORD } from "../store/action-types"
import { CHANGE_STATUS_CHANGE_PASSWORD_MODAL } from "../store/mutation-types"
import { createNamespacedHelpers } from "vuex"
const { mapActions, mapState, mapMutations } = createNamespacedHelpers("authentication")

export default {
	name: "ChangePassword",
	data() {
		return {
			showPwd: true,
			showNewPwd: true,
			showConfirmPwd: true,
			maxLength: AUTH_ROLE.PASSWORD,
			changePwdForm: {
				password: null,
				passwordConfirm: null,
			},
		}
	},
	computed: {
		...mapState({ changePwdErr: "changePwdErr" }),
	},
	watch: {
		changePwdErr(val) {
			if (val) this.$refs.form.setErrors(val)
		},
	},

	methods: {
		...mapActions({ CHANGE_PASSWORD }),
		...mapMutations({ CHANGE_STATUS_CHANGE_PASSWORD_MODAL }),
		changePwd: async function () {
			const valid = await this.$refs.form.validate()
			if (!valid) return

			const res = await this.CHANGE_PASSWORD(this.changePwdForm)
			if (!res) return
			this.$store.commit("set", ["modalShow", false])
			this.$emit("change-success")
			this.CHANGE_STATUS_CHANGE_PASSWORD_MODAL(false)
		},
		cancel: function () {
			this.$emit("hide")
		},
		showPassword() {
			this.showPwd = !this.showPwd
		},
		showNewPassword() {
			this.showNewPwd = !this.showNewPwd
		},
		showConfirmPassword() {
			this.showConfirmPwd = !this.showConfirmPwd
		},
	},
}
</script>
<style lang="scss" scoped>
.change-pwd-form {
	margin-top: 20px;
}
</style>
